<template>
    <div class="grid">
        <div class="col-12">
            <div :style="loading ? 'opacity: 30%;' : ''">
                <div class="card p-fluid w-full">
                    <div class="font-medium text-xl text-900 mb-3">
                        <div class="flex flex-row gap-2 align-items-center text-800 mb-1">
                            <AppInfoManual nomeTelaDoManual="perfis-form" />
                            <h3 class="ml-1">{{ title }}</h3>
                        </div>
                    </div>
                    <ProgressBar v-if="loading" mode="indeterminate" style="height: 0.3em" />
                    <Toast />

                    <div class="">
                        <div class="field">
                            <label for="name">Nome</label>
                            <InputText
                                id="name"
                                v-model.trim="form.name"
                                required="true"
                                autofocus
                                autocomplete="off"
                                :class="{ 'p-invalid': submitted && !form.name }"
                            />
                            <small class="p-error" v-if="submitted && !form.name">Nome é obrigatório.</small>
                        </div>
                        <div class="field">
                            <label for="permissions">Permissões</label>
                            <Button icon="pi pi-plus" class="p-button p-button-text ml-4 mr-2" type="button" @click="expandAll" />
                            <Button icon="pi pi-minus" class="p-button p-button-text mr-2" type="button" @click="collapseAll" />
                            <Tree
                                id="permissions"
                                :value="nodes"
                                selectionMode="checkbox"
                                v-model:selectionKeys="permissions"
                                :expandedKeys="expandedKeys"
                            ></Tree>
                        </div>
                    </div>
                    <div class="flex mt-5">
                        <Button label="Salvar" icon="pi pi-check" class="p-button-primary w-6" @click="save" />
                        <Button label="Voltar" icon="pi pi-times" class="p-button-text w-3 ml-auto" @click="$router.push('/profiles')" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseService from '@/services/BaseService';
import { getCurrentCompany } from '@/services/store';
import { getClientBase } from '@/services/http';
import AppInfoManual from '../../components/AppInfoManual.vue';

export default {
    data() {
        return {
            form: {},
            loading: false,
            submitted: false,
            permissions: {},
            permissoesUsuario: [],
            nodes: [],
            expandedKeys: {}
        };
    },
    async mounted() {
        this.loading = true;
        this.$service = new BaseService('/profiles');
        try {
            await this.loadNodes();
            await this.expandAll();
            if (this.$route.params.id) {
                const { data } = await this.$service.findById(this.$route.params.id);
                let permissionsFromProfile = data.permissions.map((e) => {
                    return `${e.id}_${e.permission}`;
                });
                this.form = data;
                this.permissoesUsuario = permissionsFromProfile;
                this.loadFormNodes();
            }
        } catch (error) {
            this.loading = false;
            this.$toast.add({
                severity: 'error',
                summary: 'Problemas ao carregar informações!',
                detail: error.message,
                life: 3000
            });
        }
        this.loading = false;
    },
    computed: {
        title() {
            return this.$route.params.id ? 'Editar perfil' : 'Adicionar perfil';
        },
        getCurrentCompany() {
            return getCurrentCompany();
        }
    },
    watch: {
        getCurrentCompany(newValue, oldValue) {
            if (newValue !== oldValue) {
                alert('Não é possível trocar a empresa no fluxo de criação/edição de um cadastro!');
                this.$router.push({ path: 'list' });
            }
        }
    },
    methods: {
        async save() {
            try {
                this.submitted = true;
                this.form.permissions = this.getPermissions();
                await this.$service.save(this.form);
                this.$toast.add({
                    severity: 'success',
                    summary: 'Perfil salvo com sucesso!',
                    life: 3000
                });
                this.$router.replace('list');
            } catch (err) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Problemas ao salvar o perfil!',
                    life: 3000
                });
            }
        },
        getPermissions() {
            let permissionsIds = [];
            var perms = Object.entries(this.permissions);
            for (let i = 0; i < perms.length; i++) {
                var id = +perms[i][0].split('_')[0];
                const t = permissionsIds.find((e) => e.permissionId === id);
                if (id > 0 && (t === null || t === undefined)) {
                    permissionsIds.push({ permissionId: id });
                }
            }
            return permissionsIds;
        },
        loadFormNodes() {
            for (let node of this.nodes) {
                this.nodeSelect(node);
            }
        },
        nodeSelect(node) {
            const select = this.permissoesUsuario.includes(node.key);
            if (node.children && node.children.length) {
                for (let child of node.children) {
                    this.nodeSelect(child);
                }
            }
            if (select) {
                if (node.children && node.children.length) {
                    // caso tenha filhos, temos que verificar se todos estão checked
                    this.permissions[node.key] = this.checkAllChildrenSelected(node.children, this.permissions)
                        ? { checked: true }
                        : { checked: false, partialChecked: true };
                } else {
                    // node sem filhos, apenas seta como checked
                    this.permissions[node.key] = { checked: true };
                }
            }
        },
        checkAllChildrenSelected(children, permissions) {
            const childrenKeys = children.map((child) => child.key);
            return childrenKeys.every((key) => permissions[key] && permissions[key].checked);
        },
        collapseAll() {
            this.expandedKeys = {};
        },
        expandAll() {
            for (let node of this.nodes) {
                this.expandNode(node);
            }
            this.expandedKeys = { ...this.expandedKeys };
        },
        expandNode(node) {
            this.expandedKeys[node.key] = true;
            if (node.children && node.children.length) {
                for (let child of node.children) {
                    this.expandNode(child);
                }
            }
        },
        async loadNodes() {
            const { data } = await getClientBase().get('/permissions');
            this.nodes = data;
        }
    },
    components: { AppInfoManual }
};
</script>
